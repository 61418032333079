import React, { useState } from "react";
import Styles from "./AddParticipiantModal.module.css";
import SearchBox from "../SearchBox";

const AddParticipiantModal = ({ onClose }) => {
  // State for participants on and not on the call
  const [participantsOnCall, setParticipantsOnCall] = useState([
    { id: 1, name: "John Doe", email: "JohnDoe@gmail.com", isMuted: false, onCall: true },
    { id: 2, name: "Jane Smith", email: "JaneSmith@gmail.com", isMuted: false, onCall: true },
  ]);

  const [participantsNotOnCall, setParticipantsNotOnCall] = useState([
    { id: 3, name: "Michael Brown", email: "MichaelBrown@gmail.com", onCall: false },
    { id: 4, name: "Emily Davis", email: "EmilyDavis@gmail.com", onCall: false },
  ]);

  // Function to toggle mute status
  const toggleMute = (id) => {
    setParticipantsOnCall((prevState) =>
      prevState.map((participant) =>
        participant.id === id ? { ...participant, isMuted: !participant.isMuted } : participant
      )
    );
  };

  // Function to remove participant and move them to the "not on call" list
  const removeParticipant = (id) => {
    const participantToRemove = participantsOnCall.find((p) => p.id === id);

    if (participantToRemove) {
      setParticipantsOnCall((prevState) => prevState.filter((p) => p.id !== id));
      setParticipantsNotOnCall((prevState) => [
        ...prevState,
        { ...participantToRemove, onCall: false },
      ]);
    }
  };

  // Function to add participant back to "ON THIS CALL" list
  const addParticipantToCall = (id) => {
    const participantToAdd = participantsNotOnCall.find((p) => p.id === id);

    if (participantToAdd) {
      setParticipantsNotOnCall((prevState) => prevState.filter((p) => p.id !== id));
      setParticipantsOnCall((prevState) => [
        ...prevState,
        { ...participantToAdd, isMuted: false, onCall: true },
      ]);
    }
  };

  return (
    <>
      <div className={Styles.modalOverlay} onClick={onClose}></div>
      <div className={Styles.addParticipantModal}>
        <div className={Styles.groupCallLogo}>
          <svg className={Styles.groupCallIcon}>
            <use xlinkHref={`/assets/sprite.svg#icon-groupCall`} />
          </svg>
          <button
            onClick={onClose}
            style={{
              border: "none",
              cursor: "pointer",
              position: "relative",
              bottom: "1rem",
              left: "16rem",
              height: "2rem",
              backgroundColor: "#fff",
            }}
          >
            <svg className={Styles.iconClose}>
              <use xlinkHref={`/assets/sprite.svg#icon-close-participants`} />
            </svg>
          </button>
        </div>
        <span className={Styles.groupName}>Product Scrum</span>
        <SearchBox
          style={{
            width: "39rem",
            marginTop: "2rem",
            marginBottom: "2rem",
            border: "1px solid #aaa",
            padding: "3px 1.5rem",
            marginLeft: "2rem",
          }}
          placeholder={"Search"}
        />

        {/* Participants On Call */}
        <div className={Styles.participants}>
          <span className={Styles.onCallText}>ON THIS CALL</span>
          {participantsOnCall.map((participant) => (
            <div key={participant.id} className={Styles.participant1}>
              <div className={Styles.profile}>
                <svg className={Styles.profileIcon}>
                  <use xlinkHref={`/assets/sprite.svg#icon-profile`} />
                </svg>
              </div>
              <div className={Styles.participantCard}>
                <div className={Styles.info}>
                  <span className={Styles.name}>{participant.name}</span>
                  <span className={Styles.email}>{participant.email}</span>
                </div>
                <div className={Styles.icons}>
                  <svg
                    className={Styles.icon}
                    onClick={() => toggleMute(participant.id)}
                    style={{ cursor: "pointer", width: "2rem" }}
                    title={participant.isMuted ? "Unmute" : "Mute"}          
                  >
                    <use
                      xlinkHref={
                        participant.isMuted
                          ? `/assets/sprite.svg#icon-unmute-participants`
                          : `/assets/sprite.svg#icon-mute-participants`
                      }
                    />
                  </svg>
                  <svg
                    className={Styles.icon}
                    onClick={() => removeParticipant(participant.id)}
                    style={{ cursor: "pointer" }}
                    title="Remove from call"          
                  >
                    <use xlinkHref={`/assets/sprite.svg#icon-remove-participants`} />
                  </svg>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Participants Not On Call */}
        <div className={Styles.participants}>
          <span className={Styles.onCallText}>NOT ON THIS CALL</span>
          {participantsNotOnCall.map((participant) => (
            <div key={participant.id} className={Styles.participant1}>
              <div className={Styles.profile}>
                <svg className={Styles.profileIcon}>
                  <use xlinkHref={`/assets/sprite.svg#icon-profile`} />
                </svg>
              </div>
              <div className={Styles.participantCard}>
                <div className={Styles.info}>
                  <span className={Styles.name}>{participant.name}</span>
                  <span className={Styles.email}>{participant.email}</span>
                </div>
                <div className={Styles.icons}>
                  <svg
                    className={Styles.icon}
                    onClick={() => addParticipantToCall(participant.id)}
                    style={{ cursor: "pointer" }}
                    title="Call participant"          
                  >
                    <use xlinkHref={`/assets/sprite.svg#icon-ring-participants`} />
                  </svg>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AddParticipiantModal;


