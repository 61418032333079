import React from 'react';
import PropTypes from 'prop-types';
import Styles from './Button.module.css';

const GeneralButton = ({ icon, onClick, customClass = '' }) => {
    return (
        <button
            className={`${Styles.generalButton} ${Styles[customClass]}`}
            onClick={onClick}
        >
            <svg className={Styles.icon}>
                <use xlinkHref={`#${icon}`} />
            </svg>
        </button>
    );
};

GeneralButton.propTypes = {
    icon: PropTypes.string.isRequired, // Icon name (e.g., "mic", "videocam", "call_end")
    onClick: PropTypes.func.isRequired, // Function to handle button click
    customClass: PropTypes.string, // Optional custom CSS class
};

export default GeneralButton;

