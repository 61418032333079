import React, { useState } from 'react';
import Styles from './VideoSection.module.css';
import ParticipantImage from '../../../assets/participantImage.png';
import AssistantModalButton from '../AIAssistant/ModalButton/AssistantModalButton';
import AIAssistantModal from '../AIAssistant/AIAssistantModal';
import UserVideo from '../../../assets/videoCall.jpg'

const VideoSection = ({ peers, userVideo, sharedScreenStream  }) => {
  // For simplicity, assume the first peer is the active speaker
  const activeSpeaker = peers[0]; 
  const participants = peers.slice(1); // All others are participants

  const [isModalOpen, setModalOpen] = useState(true);

  return (
    <div className={Styles.videoScreen}>
      <div className={Styles.userVideoContainer}>
      {/* <img src={UserVideo} alt="" className={Styles.video } /> */}
        {sharedScreenStream ? (
          <video
            autoPlay
            playsInline
            ref={(video) => {
              if (video) video.srcObject = sharedScreenStream;
            }}
            className={Styles.userVideo}
          />
        ) : (
          <div className={Styles.paticipantsScreen} >
            <div className={Styles.participantsGrid1}>
              <div className={Styles.participant1}>
                <img src={ParticipantImage} alt="" className={Styles.participantsImage} />
                <span className={Styles.participant1Name}>Person One </span>
                <div className={Styles.participant1Mike}>
                  <svg className={Styles.muteButtonIcon}>
                    <use xlinkHref={`/assets/sprite.svg#icon-mute-button`} />
                  </svg>
                </div>
              </div>
              <div className={Styles.participant2}>
                <img src={ParticipantImage} alt="" className={Styles.participantsImage} />
                <span className={Styles.participant1Name}>Person Two  </span>
                <div className={Styles.participant2Mike}>
                  <svg className={Styles.muteButtonIcon}>
                    <use xlinkHref={`/assets/sprite.svg#icon-mute-button`} />
                  </svg>
                </div>
              </div>
            </div>
            <div className={Styles.participantsGrid2}>
              <div className={Styles.participant3}>
                <img src={ParticipantImage} alt="" className={Styles.participantsImage} />
                <span className={Styles.participant1Name}>Person Three  </span>
                <div className={Styles.participant3Mike}>
                  <svg className={Styles.muteButtonIcon}>
                    <use xlinkHref={`/assets/sprite.svg#icon-mute-button`} />
                  </svg>
                </div>
              </div>
              <div className={Styles.participant4}>
                <img src={ParticipantImage} alt="" className={Styles.participantsImage} />
                <span className={Styles.participant1Name}>Person Four  </span>
                <div className={Styles.participant4Mike}>
                  <svg className={Styles.muteButtonIcon}>
                    <use xlinkHref={`/assets/sprite.svg#icon-mute-button`} />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        )}
          <div className={Styles.assistantmodalButton}>
          <AssistantModalButton onClick={() => setModalOpen(true)} />
          {isModalOpen && (
            <AIAssistantModal onClose={() => setModalOpen(false)} />
          )}
          </div>
        </div>
    </div>
  );
};

export default VideoSection;


