// VoiceAnalyzer.jsx
import React from 'react';
import Styles from './VoiceAnalyzerModal.module.css'

const VoiceAnalyzer = () => {
  return (
    <div className={Styles.modalBody}>
      <div className={Styles.assistantContainer}>
        <div className={Styles.header}>
          <div style={{display:'flex'}}>
          <svg className={Styles.voiceIcon }>
            <use  xlinkHref={`/assets/sprite.svg#icon-voice`} />
          </svg>  
          <div className={Styles.headerLeft}>
            <h3 className={Styles.headerLeftText}>
          <svg className={Styles.robotIcon }>
            <use  xlinkHref={`/assets/sprite.svg#icon-robot`} />
          </svg>  
          AI Assistant  
        </h3>
        <p style={{fontSize:'1.4rem'}} className="voiceAnalyzer">Voice Analyzer</p>;
          </div>
          </div>
          <div className={Styles.headerRight}>
          <svg className={Styles.editIcon }>
            <use  xlinkHref={`/assets/sprite.svg#icon-edit-3`} />
          </svg> 
          </div>
        </div>
      <button className={Styles.summaryButton}>Call Summary</button>
      </div>
    </div>
  );
};

export default VoiceAnalyzer;
