// AIAssistantModal.jsx
import React from 'react';
import Styles from './AIAssistantModal.module.css';
import VoiceAnalyzer from './VoiceAnalyzerModal/VoiceAnalyzerModal';

const AIAssistantModal = ({ onClose }) => {
  return (
    <div className={Styles.modalOverlay}>
      <div className={Styles.modalContent}>
        <div className={Styles.modalHeader}>
          <div className={Styles.profile}>
            <svg className={Styles.profileIcon }>
                 <use  xlinkHref={`/assets/sprite.svg#icon-profile`} />
			</svg>             
            <p className={Styles.audioText}>
              We need to <strong>“finish the designs system for the website on Friday”</strong> and talk…..
            </p>
          </div>
          <button className={Styles.closeButton} onClick={onClose}>
            ✖
          </button>
              </div>
              <VoiceAnalyzer></VoiceAnalyzer>
      </div>
    </div>
  );
};

export default AIAssistantModal;
