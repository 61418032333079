// AIButton.jsx
import React from 'react';
import Styles from './AssistantModalButton.module.css';

const AssistantModalButton = ({ onClick }) => {
  return (
    <button className={Styles.aiButton} onClick={onClick}>
          <svg className={Styles.aiIcon }>
            <use  xlinkHref={`/assets/sprite.svg#icon-AI`} />
          </svg> 
    </button>
  );
};

export default AssistantModalButton;
