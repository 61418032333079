import { useEffect, useRef, useState } from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import VideoSection from "./VideoSection/VideoSection";
import NotesSection from "./NotesSection/NotesSection";
import Styles from './GroupVideoCall.module.css'
import AddParticipiantModal from "../../components/AddParticipiantModal/AddParticipiantModal";
import SharedScreen from "./ScreenShareScreen/SharedScreen"


const GroupVideoCall = ({socket}) => {
    const [showNotes, setShowNotes] = useState(false);
    const [peers, setPeers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isScreenSharing, setIsScreenSharing] = useState(false);
    const [sharedScreenStream, setSharedScreenStream] = useState(null);
    const userVideo = useRef();

  const [participants, setParticipants] = useState([
    { name: 'John Doe', image: '/john.jpg' },
    { name: 'Jane Smith', image: '/jane.jpg' },
    { name: 'Emily Davis', image: '/emily.jpg' },
    { name: 'Michael Brown', image: '/michael.jpg' },
    { name: 'Sarah Wilson', image: '/sarah.jpg' },
    { name: 'Michael Brown', image: '/michael.jpg' },
    { name: 'Sarah Wilson', image: '/sarah.jpg' }
  ]);

  useEffect(() => {
    if (socket) {
      // Join the group room
      socket.emit("joinGroupVideoRoom", "groupId");

      // Listen for incoming calls
      socket.on("groupCallIncoming", ({ callerId, callerName, signal }) => {
        console.log(`${callerName} is calling...`);
        // Notify the user and prompt to answer
      });

      // Listen for new user media
      socket.on("newUserMedia", ({ userId, userName, signal }) => {
        console.log(`${userName} joined the call`);
        // Add peer connection logic here
        const newPeer = { userId, userName }; // Example
        setPeers((prev) => [...prev, newPeer]);
      });

      // Listen for media updates
      socket.on("userMediaUpdate", ({ type, currentMediaStatus, userId }) => {
        console.log(`${userId} updated ${type} -> ${currentMediaStatus}`);
        // Update the participant media states dynamically
      });

      return () => {
        // Clean up listeners on component unmount
        socket.off("groupCallIncoming");
        socket.off("newUserMedia");
        socket.off("userMediaUpdate");
      };
    }
  }, [socket]);

    useEffect(() => {
        if (!socket) {
          console.log("No socket available. Running in UI test mode.");
        }
    }, [socket]);
  
    const handleAnswerCall = ({ callerId, signal }) => {
      socket.emit("answerGroupCall", {
        groupId: "groupId",
        callerId,
        userId: socket.id,
        userName: "Your Name",
        signalData: {}, // WebRTC signal data
      });
    };
  

  // Handle opening and closing modal
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  
  const handleScreenShare = (stream) => {
    setSharedScreenStream(stream);
  };

  return (
<div className={Styles.mainContent}>
  <Header
    setShowNotes={setShowNotes}
    showNotes={showNotes}
    title="Product Scrum"
    hostName="Andrew West"
    onShareClick={() => console.log('Open share options')}
    isScreenSharing={sharedScreenStream !== null}
    participants={participants}
  />
  <div className={Styles.videoNotesContainer}>
    {isScreenSharing ? (
          <SharedScreen  content="This is the shared screen content"/>
        ) : (
            <VideoSection
              peers={peers}
              userVideo={userVideo}
              sharedScreenStream={sharedScreenStream}
            />
        )}
        {showNotes &&
          <NotesSection setShowNotes={setShowNotes} showNotes={showNotes} />}
  </div>
      <Footer
        onAddParticipantsClick={handleOpenModal}
        socket={socket}
        onScreenShare={handleScreenShare}
      />
       {/* Conditionally render the modal */}
       {showModal && <AddParticipiantModal onClose={handleCloseModal} />}
</div>
  );
};

export default GroupVideoCall;
