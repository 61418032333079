import React, { useEffect } from 'react';
import ChatStyles from '../../Chat.module.css';
import { useDispatch } from 'react-redux';
import { getAllGroupUsersAction } from '../../../../redux/actions/chatActions';
import { useHistory } from 'react-router-dom';

const SelectedGroup = ({ data, setShowGroupInfoMoal, requestVideoCallToSelectedUser }) => {
  const {
    chatUserLoading,
    checkOnlineAndGetData,
    checkOnline,
    selectedNewUser,
    selectedUser,
    setShowSecurityKey,
    showSecurityKey,
    setVideoNotesModal,
    groupName,
    socket,
    setGroupName
  } = data;

  const [lastSeenTime, setLastSeenTime] = React.useState(0);
  const [subMenu, setSubMenu] = React.useState(false);
  const [groupMembers, setGroupMembers] = React.useState([]);

  const dispatch = useDispatch()
  const history = useHistory();

  useEffect(() => {
    if (selectedNewUser) {
      setGroupName(selectedUser.name, 'namemeee')
    }
  }, [selectedNewUser])


  // React.useEffect(() => {
  //   socket.on('UpdateGroupResponse', (response) => {
  //     if (response) {
  //       if(response.groupId === selectedUser.groupId) setGroupName(response.name)
  //     }
  //   });
  // }, [selectedUser, setGroupName, socket]);

  React.useEffect(() => {
    if (
      selectedNewUser &&
      Object.keys(selectedNewUser).length > 0 &&
      selectedNewUser.lastSeenTime &&
      selectedNewUser.lastSeenTime > 0
    ) {
      setLastSeenTime(selectedNewUser.lastSeenTime);
    } else if (
      selectedNewUser &&
      Object.keys(selectedNewUser).length === 0 &&
      selectedUser &&
      selectedUser.lastSeenTime &&
      selectedUser.lastSeenTime > 0
    ) {
      setLastSeenTime(selectedUser.lastSeenTime);
    }
  }, [selectedNewUser, selectedUser]);

  const handleVideoCall = () => {
    if (selectedUser) {
      requestVideoCallToSelectedUser(selectedUser); // Trigger any existing logic
      history.push('/group-video-call-new'); // Navigate to the video call screen
    }
  };

  //   const getGroupUsers = React.useCallback(async () => {
  //     try {
  //         const groupData = await dispatch(getAllGroupUsersAction(selectedUser.));
  //         const members = groupData?.data ?? [];
  //         setGroupMembers(members);
  //     } catch (error) {
  //         console.error('Error fetching group data:', error);
  //     }
  // }, [dispatch, groupId]);

  return (
    <div className={ChatStyles.chatBox_user}>
      <div className={ChatStyles.chatBox_user_data}>
        <div className={ChatStyles.chatLists_user_imageBox}>
          <img
            src='/assets/icon-group-logo.jpg'
            alt='User'
            className={ChatStyles.chatLists_user_image}
          />
        </div>
        <div>
          <h3 className={ChatStyles.chatLists_user_name}>
            {groupName}
          </h3>
          {/* <p className={ChatStyles.chatLists_user_msg}>
            {selectedUser && selectedUser?.members?.length > 1 &&
              <span>
                {selectedUser?.members?.length} members
              </span>}
          </p> */}
        </div>
      </div>

      {!chatUserLoading && (
        <div className={ChatStyles.chatBox_user_right_container} style={{gap: '0.4rem'}}>

          {/* Video calling button */}
          <button
            // onClick={() => requestVideoCallToSelectedUser(selectedUser)}
            onClick={handleVideoCall} 
            className={ChatStyles.video_call_button}
          >
            <svg>
              <use xlinkHref={`/assets/sprite.svg#icon-video-call`} />
            </svg>
          </button>

          <div>
            {/* <div className='dropdown-a' onClick={() => setShowGroupInfoMoal(true)}>
              <svg style={{ width: '24px', height: '24px', marginTop: '2px' }}>
                <use xlinkHref={`/assets/sprite.svg#icon-info-icon`} />
              </svg>
            </div> */}
          </div>
          <div
            className='dropdown'
            onMouseOver={() => setSubMenu(!subMenu)}
            onMouseOut={() => setSubMenu(!subMenu)}
          >
            <div className='dropdown-a'>
              <svg>
                <use xlinkHref={`/assets/sprite.svg#icon-three_dots`} />
              </svg>
            </div>
            <input
              type='checkbox'
              checked={subMenu}
              onChange={() => {}}
            />
            <div className='dropdown-c'>
              <ul style={{ width: '15rem' }}>
                <li
                  style={{
                    maxHeight: '50vh',
                    overflowY: 'auto',
                  }}
                >
                  {/* <button
                    className='dropdown_link_btn dropdown_link'
                    onClick={() => setShowSecurityKey(!showSecurityKey)}
                  >
                    Security key
                  </button> */}
                  <button
                    className='dropdown_link_btn dropdown_link'
                    onClick={() => setShowGroupInfoMoal(true)}
                  >
                    Group Info
                  </button>
                  <button
                    className='dropdown_link_btn dropdown_link'
                    onClick={() => setVideoNotesModal(true)}
                  >
                    Video Notes
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

      )}

    </div>
  );
};

export default SelectedGroup;
