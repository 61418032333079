import React, { useState } from 'react';
import Styles from './Footer.module.css';
import Button from '../../../components/GroupVideoCallButton/Button'

const Footer = ({ onAddParticipantsClick, socket, onScreenShare }) => {
  const [isMicrophoneOn, setMicrophoneOn] = useState(true);
  const [isVideoOn, setVideoOn] = useState(true);
  const [isScreenSharing, setScreenSharing] = useState(false);
  const [isNoiseSuppressionEnabled, setNoiseSuppressionEnabled] = useState(false);

const handleMicrophoneToggle = () => {
  const newMicrophoneState = !isMicrophoneOn;
  setMicrophoneOn(newMicrophoneState);
  socket.emit("updateMedia", {
    type: "audio",
    currentMediaStatus: newMicrophoneState,
    userId: socket.id,
    groupId: "groupId",
  });
  console.log('Microphone toggled:', newMicrophoneState);
};

const handleVideoToggle = () => {
  const newVideoState = !isVideoOn;
  setVideoOn(newVideoState);
  socket.emit("updateMedia", {
    type: "video",
    currentMediaStatus: newVideoState,
    userId: socket.id,
    groupId: "groupId",
  });
  console.log('Video toggled:', newVideoState);
};

  const handleEndCall = () => {
    socket.emit('EndGroupCall');
    console.log('Call ended');
  };

  const handleReconnectToCall = () => {
    socket.emit('reconnectToCall');
    console.log('Reconnected to the call');
  };

  const handleToggleNoiseSuppression = () => {
    const newNoiseSuppressionState = !isNoiseSuppressionEnabled;
    setNoiseSuppressionEnabled(newNoiseSuppressionState);
    socket.emit('toggleNoiseSuppression', { enabled: newNoiseSuppressionState });
    console.log('Noise suppression toggled:', newNoiseSuppressionState);
  };

  // const handleStartScreenSharing = () => {
  //   const newScreenSharingState = !isScreenSharing;
  //   setScreenSharing(newScreenSharingState);
  //   // onScreenShareToggle(newScreenSharingState);
  // };

  const handleStartScreenSharing = async () => {
    if (!isScreenSharing) {
      try {
        const stream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
        });
        setScreenSharing(true);
        onScreenShare(stream); // Pass the stream to the parent component
        socket.emit('startScreenSharing', { streamId: stream.id });
        console.log('Started screen sharing');
      } catch (err) {
        console.error('Error starting screen sharing:', err);
      }
    } else {
      setScreenSharing(false);
      onScreenShare(null); // Stop sharing
      socket.emit('stopScreenSharing');
      console.log('Stopped screen sharing');
    }
  };
  
  return (
    <div className={Styles.groupVideoCall}>
      <div className={Styles.centerButtons}>
      <div className={Styles.videoButton}>
    <Button
        icon="/assets/sprite.svg#video-button"
        onClick={handleVideoToggle}
        customClass="green"
      />
        <svg className={Styles.videoButtonIcon }>
          <use  xlinkHref={`/assets/sprite.svg#video-button`} />
			  </svg>
      </div>
      <div className={Styles.microphoneButton}>
    <Button
        icon="videocam"
        onClick={handleMicrophoneToggle}
        customClass="orange"
        />
        <svg className={Styles.microphoneButtonIcon }>
          <use  xlinkHref={`/assets/sprite.svg#microphone-button`} />
			  </svg>
      </div>
      <div className={Styles.callEndButton} >
    <Button
        icon="call_end"
        onClick={handleEndCall}
        customClass="red"
        />
        <svg className={Styles.callEndButtonIcon }>
          <use  xlinkHref={`/assets/sprite.svg#icon-call-end-button`} />
			  </svg>
      </div>
      </div>
      <div className={Styles.footerLeft}>
      <div className={Styles.miniPlayerButton}>
      <svg className={Styles.miniPlayerIcon }>
          <use  xlinkHref={`/assets/sprite.svg#icon-miniPlayer`} />
        </svg>
          <span className={Styles.iconName}>
            Miniplayer
          </span>
      </div>
      <div className={Styles.miniPlayerButton}  onClick={onAddParticipantsClick} >
      <svg className={Styles.miniPlayerIcon } >
          <use  xlinkHref={`/assets/sprite.svg#icon-addParticipants`} />
        </svg>
          <span className={Styles.addParticipantsIcon}>
            Add Participants
          </span>
      </div>
        <div className={Styles.miniPlayerButton}
             onClick={handleStartScreenSharing}>
      <svg className={Styles.miniPlayerIcon }>
          <use  xlinkHref={`/assets/sprite.svg#icon-screenShare`} />
        </svg>
          <span className={Styles.screenShareIcon}>
            {isScreenSharing ? 'Stop Sharing' : 'Share Screen'}
          </span>
      </div>
      </div>
</div>
  );
};

export default Footer;
