import React from 'react';
import Styles from './Header.module.css';
import ContiniaLogo from '../../../assets/logo.png'

const Header = ({ title, hostName, onBackClick, onShareClick, isScreenSharing, participants }) => {
  
  const visibleParticipants = participants.slice(0, 5);
  const extraParticipantsCount = participants.length - visibleParticipants.length;

  return (
    <div className={Styles.headerContainer}>
      <div className={Styles.logoContainer}>
        <img src={ContiniaLogo} alt="" className={Styles.logo } />
      </div>
      <div className={Styles.header}>
        <div className={Styles.headerTitle}>
      {/* <button className={Styles.backButton} onClick={onBackClick}>
      <svg className={Styles.backButtonIcon }>
          <use  xlinkHref={`/assets/sprite.svg#icon-back-button`} />
			</svg>
      </button> */}
      <div className={Styles.headerContent}>
        <h3 className={Styles.meetingTitle}>{title}</h3>
      </div>
      {/* <button className={Styles.shareButton} onClick={onShareClick}>
      <svg className={Styles.shareIcon }>
          <use xlinkHref={`/assets/sprite.svg#icon-share-button`} />
			</svg>
      </button> */}
        </div>
        <div className={Styles.totalParticipants}>
            5 of 12 in the call | 10:20
        </div>
      </div>
        {/* Participants Section */}
{isScreenSharing && (
  <div className={Styles.participantThumbnailsContainer}>
    {visibleParticipants.map((participant, index) => (
      <div key={index} className={Styles.thumbnail}>
        <img
          src={participant.image || '/default-avatar.png'} // Fallback image for participants without an image
          alt={participant.name || 'Participant'}
          className={Styles.thumbnailImage}
        />
        <span className={Styles.participantName}>
          {participant.name || 'Anonymous'}
        </span>
      </div>
    ))}
    {extraParticipantsCount > 0 && (
      <div className={Styles.extraParticipants}>
        +{extraParticipantsCount} others
      </div>
    )}
  </div>
)}
    </div>
  );
};

export default Header;


