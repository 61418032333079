import React from "react";
import Styles from "./SharedScreen.module.css";

const SharedScreen = ({ content }) => {
  
  return (
    <div className={Styles.sharedScreen}>
      <h2>Shared Screen</h2>
      <div className={Styles.sharedContent}>
        {content || "Screen sharing is active."}
      </div>
    </div>
  );
};

export default SharedScreen;

